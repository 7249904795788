/*
 * toast.js CSS
 */

.toast {
  padding: 8px 35px 8px 14px;
  margin-bottom: 8px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  border: 2px solid;
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;
  box-shadow:#999 0 0 8px;
}
.toast:hover {
  cursor: pointer;
  box-shadow:#666 0 0 8px;
}

#toast-container {
  width: 300px;
  top: 12px;
  left: 12px;
  position: fixed;
  z-index: 9999;
}

.toast-title {
  font-weight: bold;
}

.toast-message {
}

.toast-success {
  color: #468847;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.toast-error {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
}

.toast-info {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.toast-warning {
  color: #c09853;
  background-color: #fcf8e3;
  border-color: #fbeed5;
}


